import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import config from "../../../content/meta/config";

const Seo = (props) => {
  const { data, manualTitle, manualDescription, manualSlug } = props;

  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebook {
            appId
          }
          image
        }
      }
    }
  `);

  // const { data } = props;
  const postTitle = ((data || {}).frontmatter || {}).title;
  const postDescription = ((data || {}).frontmatter || {}).description;
  const postCover = ((data || {}).frontmatter || {}).cover;
  let postSlug = ((data || {}).fields || {}).slug;
  if (manualSlug !== undefined) {
    postSlug = manualSlug;
  }

  // console.log("data: ", data);

  let title = postTitle ? `${postTitle} - ${config.shortSiteTitle}` : config.siteTitle;
  if (manualTitle !== undefined) {
    title = manualTitle;
  }
  // console.log("title: ", title);
  let description = postDescription ? postDescription : config.siteDescription;
  if (manualDescription !== undefined) {
    description = manualDescription;
  }
  const image = postCover ? postCover : config.siteImage;
  const url = config.siteUrl + postSlug;
  const appId = site.siteMetadata.facebook.appId;

  const isHomePage = () => {
    if (url === "https://theseodigest.com" || url === "https://theseodigest.com/") {
      return true;
    }
    return false;
  };

  const structdata = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "The SEO Digest",
    url: "https://theseodigest.com",
    logo: "https://theseodigest.com/icons/icon.png",
    sameAs: [
      "@the_seo_digest",
      "https://www.linkedin.com/company/the-seo-digest",
      "https://www.facebook.com/TheSEODigest",
    ],
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: config.siteLanguage,
        prefix: "og: http://ogp.me/ns#",
      }}
    >
      {/* General tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* OpenGraph tags */}
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content={appId} />
      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="The SEO Digest" />
      <meta name="twitter:url" content={url} />
      <meta
        name="twitter:creator"
        content={config.authorTwitterAccount ? config.authorTwitterAccount : ""}
      />
      <meta name="twitter:image" content={image} />
      {isHomePage && <script type="application/ld+json">{JSON.stringify(structdata)}</script>}
    </Helmet>
  );
};

Seo.propTypes = {
  data: PropTypes.object,
  manualTitle: PropTypes.string,
  manualDescription: PropTypes.string,
  manualSlug: PropTypes.string,
  // facebook: PropTypes.object.isRequired
};

export default Seo;
